import * as React from "react"
import Sidebar from "./sideBar"
import TopNavBar from "./topNavBar"

const Layout = ({ children }) => {

  return (
    <div className="flex flex-col ">
      <TopNavBar />
      <div className="flex flex-row mt-16 ">
        <Sidebar />
        <div className="m-auto w-7/12 p-5">
          {children}
        </div>

      </div>
    </div>

  )
}

export default Layout
