import { Link } from 'gatsby';
import React from 'react';
import { useStaticQuery, graphql } from "gatsby"


export default function Sidebar() {
  const data = useStaticQuery(graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        id
        excerpt
        fields {
          slug
        }
        frontmatter {
          title
        }
      }
    }
  }
`)

  const posts = data.allMarkdownRemark.nodes

  return (
    <div className=" text-black overflow-y-scroll flex flex-col h-screen w-1/5 fixed box-border md:box-content  ...">
      <div className="w-screen flex flex-col ">
        <span className="text-base w-full mx-8 my-4 ">POSTS</span>
        {posts.map((post) => (
          <Link key={post.id} className="text-base w-full my-4 mx-8 " to={post.fields.slug}
            activeStyle={{
              color: "limegreen",
              borderLeft: "5px solid",
              borderColor: "limegreen",
              fontWeight: "500",
              paddingLeft: "5px",
            }}
            style={{
              borderLeft: "5px solid",
              borderColor: "white",
              paddingLeft: "5px",
            }}>
            {post.frontmatter.title}
          </Link>
        ))}

      </div>
    </div >
  );
}
