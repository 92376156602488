import * as React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout/layout"
import Seo from "../components/seo"


const BlogPostTemplate = ({ data }) => {
  const post = data.markdownRemark
  const { previous, next } = data
  return (
    <Layout>
      <Seo
        titlePost={post.frontmatter.title}
        description={post.excerpt}
      />
      <div className=" max-w-full break-normal  prose prose-md prose-green">
        <h1>{post.frontmatter.title}</h1>
        <p>{post.frontmatter.author}<br /> {post.frontmatter.date}</p>

        <article
          dangerouslySetInnerHTML={{ __html: post.html }}
        />
        <hr />

      </div>
      <div >
        <ul
          className="`flex justify-between p-0"
        >
          <li>
            {previous && (
              <Link to={previous.fields.slug} rel="prev">
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={next.fields.slug} rel="next">
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </ul>
      </div>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  )
  {
          markdownRemark(id: {eq: $id }) {
          id
      excerpt(pruneLength: 160)
      html
      frontmatter {
          title
        date
        author
      }
    }
    previous: markdownRemark(id: {eq: $previousPostId }) {
          fields {
          slug
        }
      frontmatter {
          title
        }
    }
    next: markdownRemark(id: {eq: $nextPostId }) {
          fields {
          slug
        }
      frontmatter {
          title
        }
    }
  }
`

